import React, {useEffect, useState} from 'react';
import FontAwesome from 'react-fontawesome';
import './NavBar.css';
import { ReactComponent as Github } from './../../assets/images/github-gray.svg';
import githubGray from './../../assets/images/github-gray.svg';
import linkedinGray from './../../assets/images/linkedin-gray.svg';
import emailGray from './../../assets/images/email-gray.svg';
import githublightBlue from './../../assets/images/github-lightBlue.svg';
import linkedinBlue from './../../assets/images/linkedin-lightBlue.svg';
import emaillightBlue from './../../assets/images/email-lightBlue.svg';

const linkedinURL = 'https://www.linkedin.com/in/daniel-c-a6407710b/';
const githubURL = 'https://github.com/dan4310';


const NavBar = () => {
    const [navBar, setNavBar] = useState(false);
    const [button, setButton] = useState(false);
    const [click, setClick] = useState(false);
    const [contact, setContact] = useState(true);

    useEffect(() => {
        if (window.innerWidth <= 760) {
            setButton(true);
        }
        if (window.innerWidth >= 1000) {
            setContact(false);
        }
    }, [])

    const changeBackground = () => {
        if (window.scrollY >= 20) {
            setNavBar(true);
        } else {
            setNavBar(false);
        }
    };

    const showButton = () => {
        if (window.innerWidth <= 760) {
            setButton(true);
        } else {
            setButton(false);
            setClick(false);
        }
    };

    const showContact = () => {
        if (window.innerWidth >= 1000) {
            setContact(false);
        } else {
            setContact(true);
        }
    }

    const handleMenuClick = () => {
        setClick(!click);
    };

    window.addEventListener('resize', showButton);
    window.addEventListener('resize', showContact);

    window.addEventListener('scroll', changeBackground);

    return (
        <div className={navBar ? "NavBar active" : "NavBar"}>
            <div className="logo">
            <a href="/" onClick={handleMenuClick}>
                <p className="firstName">D</p>
                <p className="lastName">C</p>
            </a>  
            </div>
            <div className='nav-bar'>
                <nav>
                    <ul className={click ? 'navMenu active' : 'navMenu'}>
                        <li>
                            <a href="/#home" onClick={handleMenuClick}>Home</a>
                        </li>
                        <li>
                            <a href="/#about" onClick={handleMenuClick}>About</a>
                        </li>
                        <li>
                            <a href="/#skills" onClick={handleMenuClick}>Skills</a>
                        </li>
                        <li>
                            <a href="/#projects" onClick={handleMenuClick}>Projects</a>
                        </li>
                        { contact &&
                            <li>
                                <a href="/#contact" onClick={handleMenuClick}>Contact</a>
                            </li>
                        }
                    
                    </ul>
                </nav>
            </div>
            
                <div className='socials-bar'>
                    <a href={githubURL}>
                        {!navBar ?
                            <img className='github-nav' src={githubGray} alt='github'></img>
                            :
                            <img className='github-nav' src={githublightBlue} alt='github'></img>
                        }
                    </a>
                    <a href={linkedinURL}>
                        {!navBar ?
                                <img className='linkedin-nav' src={linkedinGray} alt='linkedin'></img>
                                :
                                <img className='linkedin-nav' src={linkedinBlue} alt='linkedin'></img>
                        }
                    </a>
                    <a href='/#contact'>
                        {!navBar ?
                                <img className='email-nav' src={emailGray} alt='email'></img>
                                :
                                <img className='email-nav' src={emaillightBlue} alt='email'></img>
                        }
                    </a>
                    
                    
                </div>
            
            {button && 
                <div className='menuBars-icon' onClick={handleMenuClick}>
                    <FontAwesome
                        className="menuBars"
                        name={click ? "times" : "bars"}
                        size="2x"
                    />
                </div>

            }
        </div>
    )
}

export default NavBar;