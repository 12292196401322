import './Projects.css'
import Project from '../Project/Project';
import headerSmall from '../../assets/images/headerSmall.svg'
import signin from '../../assets/images/signin.png';
import food from '../../assets/images/food.png';
import order from '../../assets/images/order.png';
import database from '../../assets/images/database_1.png';
import graph from '../../assets/images/graph.png';
import graphInter from '../../assets/images/graphInter.png';
import sorting1 from '../../assets/images/sorting_1.png';
import sorting2 from '../../assets/images/sorting_2.png';
import filmCriticHome from '../../assets/images/filmCriticHome.png';
import filmCriticMovie from '../../assets/images/filmCriticMovie.png';

const Projects = () => {
    const desc1 = 'Food ordering app for the Tony\'s Tacos restaurant written with React Native and the Expo CLI. This app used Firebase authentication and Firebase\'s Realtime Database to store data for all the food items.';
    const desc2 = 'A database analysis website built with HTML, CSS and JavaScript which allows to the user to view all the records of the database and search/filter by different properties. This database uses SQL commands in the backend in order to query the large data set efficiently.';
    const desc3 = 'A java program which allows the user to experiment wave functions. While I was studying Classical and Quantum Waves I was amazed at the prevelance and importance of waves in the physical world. You can adjust the parameters of each wave function and even superimpose them.'
    const desc4 = 'A React.js app that uses material-ui to display some of the important sorting algorithms, such as, bubble sort, quick sort, merge sort, and heap sort. Three visual sorting speed settings, variable number of sorting bins and display of sorting performance.'
    const desc5 = 'A React.js frontend with a node.js, Express, and MySQL backend application which turns movie reviews into an everyman social community instead of the coprorate clique of Hollywood. Uses The Movie Database for movie information.';

    return (
        <div className = 'projects' id='projects'>
        <div className='header-container'>
            <h3 className='header-text'>Projects</h3>
            <img className='header-svg' src={headerSmall} alt='Projects'/>
        </div>
        <Project type='type-1' name='Food Ordering App' desc={desc1} images={[signin, food, order]} link='https://github.com/dan4310/TonysTacosApp'/>
        <Project type='type-2' name='Sorting Visualizer' desc={desc4} images={[sorting1, sorting2]} website='https://daniel.courtneyco.com/Sorting' link='https://github.com/dan4310/SortingVisualizer'/>
        <Project type='type-2' name='Film Critic Social' desc={desc5} images={[filmCriticHome, filmCriticMovie]} link='https://github.com/dan4310/FilmCritic'/>
        <Project type='type-1' name='MLB Database' desc={desc2} images={[database]} website='https://daniel.courtneyco.com/MLBdatabase' link='https://github.com/dan4310/DatabaseWebsite'/>
        <Project type='type-2' name='Wave Function Plotter' desc={desc3} images={[graph, graphInter]} link='https://github.com/dan4310/WaveFunctions'/>
        </div>
    )
}

export default Projects;