import './Contact.css';
import header from '../../assets/images/headerSmall.svg'
import github from '../../assets/images/github-orange.svg';
import linkedin from '../../assets/images/linkedin-orange.svg';
import file from '../../assets/images/file-lightBlue.svg';
import myResume from '../../assets/files/Resume.pdf'

const githubUrl = 'https://github.com/dan4310';
const linkedinUrl = 'https://www.linkedin.com/in/daniel-c-a6407710b/';

const Contact = () => {
    return (
        <div className='contact' id='contact'>
            <div className='header-container'>
                <h3 className='header-text'>Contact</h3>
                <img className='header-svg' src={header} alt='header image'></img>
            </div>
            <div className='info-container'>
                <h1 className='looking-forward'>I am looking forward to working with you!</h1>
                <div className='contacts-container'>
                    <h2 className='email'>dan4310@gmail.com</h2>
                    <div className='contacts'>
                        <a href={githubUrl}>
                            <img className='github-svg' src={github} alt='github'></img>
                        </a>
                        <a href={linkedinUrl}>
                            <img className='linkedin-svg' src={linkedin} alt='linkedin'></img>
                        </a>
                    </div>
                    <div className='resume-container'>
                        <a href={myResume}>
                            <img className='file-svg' src={file} alt='resume'></img>
                        </a>
                        <h2 className='download-resume'>Download my resume</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;