import './Project.css';
import github from '../../assets/images/github-purple.svg';
import githubBar from '../../assets/images/githubBar.svg';

const Project = ({type, name, desc, images, link, website}) => {

    const renderImage = (img) => {
        return (
            <div className='project-card-1'>
                <img className='project-img-1' src={img} alt="image"/>
            </div>
        )
    }

    const renderImages = () => {
        switch (type) {
            case 'type-1':
                return (
                    <div className='img-container-1'>
                        {images.map(img => renderImage(img))}
                    </div>
                )
            case 'type-2':
                return (
                    <div className='img-container-1'>
                        <div className='img-container-2'>
                        {images.map(img => renderImage(img))}
                        </div>
                    </div>
                    
                )
        }
    }

    return (
            <div className='subject-container'>
                <div className='row-container' >
                    {renderImages()}

                    <div className='text-container'>
                        <h1 className='project-name'>{name}</h1>
                        {website && 
                            <a className='siteLink' href={website}>View Project</a>
                        }
                        <div className='project-desc-container'>
                            <h2 className='project-desc'>{desc}</h2>
                        </div>
                    </div>
                </div>
                

                <div className='github-container'>
                    <a className='github-link' href={link}>
                        <img className='github-icon' src={github} alt="gibhub"/>
                    </a>
                    <img className='github-bar' src={githubBar} alt="githubBar"/>
                </div>
            </div> 
        
    )
}

export default Project;