import  "./Home.css";
import heroImage from './../../assets/images/hero-image.svg';
import slider from './../../assets/images/slider.svg';

const Home = () => {
    return (
        <div className='home-container'>
        <div className="Home" id='home'>
            <div className="Container">
                <div className='title-header'>
                    <h1 className="line1">Efficent Code</h1>
                    <h1 className="line2">Beautifully Designed</h1>
                </div>
                
                <div className="slogan">
                    <h2 className='meet'>Meet Daniel Courtney</h2>
                </div>
                <div className='slider-container'>
                    <img className='slider' src={slider} alt="slider"/>
                </div>
            </div>
        </div>
        
        <img className='hero-image' src={heroImage} alt="heroImage"/>
        
        <div className='empty-container'></div>
        </div>
    )
}

export default Home;