import './Skills.css';
import header from './../../assets/images/header.svg';
import file from './../../assets/images/file-gray.svg';
import myResume from '../../assets/files/Resume.pdf'

const Skills = () => {
    return (
        <div className='skills' id="skills">
                    <img className='header' src={header} alt="header"/>
                    <h3 className='skills-text'>Skills</h3>
                    <div className='skills-section'>
                        <div className='grid-container'>
                            <div className='grid-item-1'>
                                <h1 className='item-1'>Software</h1>
                            </div>
                            <div className='grid-item-2'>
                                <h1 className='item-2'>Course Work</h1>
                            </div>
                            <div className='grid-item-3'>
                                <div className='skill-card'>
                                    <h2 className='skill'>HTML5 - CSS</h2>
                                    <h2 className='skill'>JavaScript - React.js</h2>
                                    <h2 className='skill'>React Native</h2>
                                    <h2 className='skill'>Expo CLI - Java</h2>
                                    <h2 className='skill'>Python - SQL</h2>
                                    <h2 className='skill'>Full-Stack</h2>
                                </div>
                                
                            </div>
                            <div className='grid-item-4'>
                            <div className='skill-card'>
                                    <h2 className='skill'>Data Structures</h2>
                                    <h2 className='skill'>Object-oriented</h2>
                                    <h2 className='skill'>Operating Systems</h2>
                                    <h2 className='skill'>Linear Algebra</h2>
                                    <h2 className='skill'>Multivariable Calculus</h2>
                                    <h2 className='skill'>Probability {'&'} Statistics</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='resumepopup-container'>
                        <a href={myResume}>
                            <img className='file-svg' src={file} alt='resume'></img>
                        </a>
                        <h2 className='resume-popup'>My resume</h2>
                    </div>          
        </div>
    )
}

export default Skills;